footer {
    background: rgba(207, 5, 5, 0);
    background: -webkit-linear-gradient(59deg, #376c86, rgba(61, 20, 20, 0));
    background: linear-gradient(59deg, #0985c4,  rgba(109, 44, 44, 0));
    color: rgb(71, 51, 51);
    margin-top:100px;
  }
  
  footer a {
    color: rgb(131, 7, 7);
    font-size: 14px;
    transition-duration: 0.2s;
  }
  
  footer a:hover {
    color: #d46718;
    text-decoration: none;
  }
  
  .copy {
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid #FFFFFF;
  }
  
  .footer-middle {
    padding-top: 2em;
    color: white;
  }
 .h4{
    color: #7d7575;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
 } 
  /*SOCİAL İCONS*/
  
  /* footer social icons */
  
  ul.social-network {
    list-style: none;
    display: inline;
    margin-left: 0 !important;
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 5px;
  }
  
  
  /* footer social icons */
  
  .social-network a.icoFacebook:hover {
    background-color: #3B5998;
  }
  
  .social-network a.icoLinkedin:hover {
    background-color: #007bb7;
  }
  
  .social-network a.icoFacebook:hover i,
  .social-network a.icoLinkedin:hover i {
    color: #fff;
  }
  
  .social-network a.socialIcon:hover,
  .socialHoverClass {
    color: #44BCDD;
  }
  
  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
  
  .social-circle li i {
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
  
  .social-circle li a:hover i,
  .triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .social-circle i {
    color: #595959;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
  }
  
  .social-network a {
    background-color: #F9F9F9;
  }
  .footer-pad{
      text-decoration: solid;
      color: #7d7575;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;

  }